var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cp-home-content" }, [
    _vm.showHeader
      ? _c(
          "div",
          { staticClass: "cp-home-header" },
          [
            _vm.themeData === "blue"
              ? _c("cp-sby-header", { on: { "on-command": _vm.scenicCommand } })
              : _vm.themeData === "yellow"
              ? _c("cp-header", { on: { "on-command": _vm.scenicCommand } })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm.reloadiframe && _vm.subsystemUrl
      ? _c("iframe", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading.fullscreen.lock",
              value: _vm.loading,
              expression: "loading",
              modifiers: { fullscreen: true, lock: true },
            },
          ],
          staticClass: "container",
          class: { "policy-decision": _vm.isPolicy(_vm.iframeInfo.name) },
          attrs: {
            id: "containerFrm2",
            "element-loading-text": "正在加载，请稍后",
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "rgba(0, 0, 0, 0.8)",
            src: _vm.subsystemUrl,
          },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }