var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cp-home-header" },
    [
      _vm._m(0),
      _c("div", { staticClass: "cp-header-info" }, [
        _c("div", { staticClass: "cp-header-left-info" }, [
          _c(
            "div",
            { staticClass: "menu-list menu-pd-right" },
            _vm._l(_vm.scenicMenuLeft, function (item, index) {
              return _c(
                "div",
                {
                  key: `menu-${index}`,
                  staticClass: "menu-item",
                  class: { active: item.name === _vm.selectInfo.name },
                  on: {
                    click: function ($event) {
                      return _vm.scenicMenu(item.name)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item.name) + " ")]
              )
            }),
            0
          ),
        ]),
        _c(
          "div",
          { staticClass: "cp-header-title" },
          [
            _vm.selectInfo.name
              ? [
                  _vm.selectInfo.iconUrl
                    ? _c("img", {
                        attrs: {
                          src: _vm.selectInfo.iconUrl,
                          alt: _vm.selectInfo.name,
                          height: "50px",
                        },
                      })
                    : _vm.systemLogo || _vm.systemName
                    ? _c("div", { staticClass: "cp-header-logo" }, [
                        _vm.systemLogo
                          ? _c("div", { staticClass: "logo-pic" }, [
                              _c("img", {
                                attrs: {
                                  src: _vm.systemLogo,
                                  width: "50px",
                                  height: "50px",
                                  alt: _vm.systemName,
                                },
                              }),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "web-name" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.systemName
                                  ? `${_vm.systemName}-${_vm.selectInfo.name}`
                                  : _vm.selectInfo.name
                              ) +
                              " "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]
              : _vm._e(),
          ],
          2
        ),
        _c("div", { staticClass: "cp-header-right-info" }, [
          _c(
            "div",
            { staticClass: "menu-list menu-pd-left" },
            _vm._l(_vm.scenicMenuRight, function (item, index) {
              return _c(
                "div",
                {
                  key: `menu-${index}`,
                  staticClass: "menu-item",
                  class: { active: item.name === _vm.selectInfo.name },
                  on: {
                    click: function ($event) {
                      return _vm.scenicMenu(item.name)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item.name) + " ")]
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "navigation-list" },
            [
              _c(
                "el-dropdown",
                { on: { command: _vm.userMenu } },
                [
                  _c("div", { staticClass: "nav-box" }, [
                    _c("i", { staticClass: "cp-icon" }, [_vm._v("")]),
                  ]),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "cp-home-dropdown",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown",
                    },
                    [
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "backindex" } },
                        [_vm._v(" 系统主页 ")]
                      ),
                      _vm.localDeployment && !_vm.isDaqsoftOs
                        ? _c(
                            "el-dropdown-item",
                            { attrs: { command: "quit", divided: "" } },
                            [_vm._v(" 退出系统 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("cp-weather-dialog", { attrs: { show: _vm.show, data: _vm.weather } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cp-header-line" }, [
      _c("div", { staticClass: "cp-header-left" }),
      _c("div", { staticClass: "cp-header-center" }),
      _c("div", { staticClass: "cp-header-right" }, [
        _c("div", { staticClass: "cp-header-right-info" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }