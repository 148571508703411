var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cp-home-header" },
    [
      _vm._m(0),
      _c("div", { staticClass: "cp-header-info" }, [
        _c("div", { staticClass: "cp-header-left-info" }, [
          _c("div", { staticClass: "cp-visual-weather" }, [
            _vm.todayWeather.cond && _vm.todayWeather.cond.unicode_d
              ? _c("i", {
                  staticClass: "daq-weather cp-visual-weather-icon",
                  staticStyle: { "margin-right": "5px", "font-size": "16px" },
                  domProps: {
                    innerHTML: _vm._s(_vm.todayWeather.cond.unicode_d),
                  },
                })
              : _vm._e(),
            _c("p", { staticClass: "cp-visual-weather__left-weather" }, [
              _vm._v(" " + _vm._s(_vm.weatherInfo) + " "),
            ]),
            _c("div", { staticClass: "cp-split-line" }),
            _c("p", { staticClass: "cp-visual-infos__time" }, [
              _c(
                "span",
                {
                  staticClass: "time-today",
                  staticStyle: { "margin-left": "5px" },
                },
                [_vm._v(_vm._s(_vm.today))]
              ),
              _c("span", { staticClass: "time-today time-today-now" }, [
                _vm._v(_vm._s(_vm.nowTime)),
              ]),
            ]),
            _c("div", { staticClass: "cp-split-line" }),
            _c(
              "p",
              {
                staticClass: "cp-visual-weather__btn",
                on: { click: _vm.showWeatherDialog },
              },
              [
                _c("span", [_vm._v("气象数据")]),
                _c("i", { staticClass: "cp-icon" }, [_vm._v("")]),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "cp-header-title" },
          [
            _vm.selectInfo.name
              ? [
                  _vm.selectInfo.iconUrl
                    ? _c("img", {
                        attrs: {
                          src: _vm.selectInfo.iconUrl,
                          alt: _vm.selectInfo.name,
                          height: "50px",
                        },
                      })
                    : _vm.systemLogo || _vm.systemName
                    ? _c("div", { staticClass: "cp-header-logo" }, [
                        _vm.systemLogo
                          ? _c("div", { staticClass: "logo-pic" }, [
                              _c("img", {
                                attrs: {
                                  src: _vm.systemLogo,
                                  width: "50px",
                                  height: "50px",
                                  alt: _vm.systemName,
                                },
                              }),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "web-name" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.systemName
                                  ? `${_vm.systemName}-${_vm.selectInfo.name}`
                                  : _vm.selectInfo.name
                              ) +
                              " "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]
              : _vm._e(),
          ],
          2
        ),
        _c("div", { staticClass: "cp-header-right-info" }, [
          _c(
            "div",
            { staticClass: "navigation-list" },
            [
              _c(
                "el-dropdown",
                {
                  attrs: { placement: "bottom", trigger: "click" },
                  on: { command: _vm.scenicMenu },
                },
                [
                  _c("div", { staticClass: "nav-box" }, [
                    _c("i", { staticClass: "cp-icon" }, [_vm._v("")]),
                  ]),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "cp-home-dropdown",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown",
                    },
                    _vm._l(_vm.scenicHomeMenu, function (item, index) {
                      return _c(
                        "el-dropdown-item",
                        {
                          key: `menu-${index}`,
                          class: {
                            selectMenu: item.name === _vm.selectInfo.name,
                          },
                          attrs: { divided: index !== 0, command: item.name },
                        },
                        [
                          _c("i", {
                            staticClass: "cp-icon",
                            domProps: { innerHTML: _vm._s(item.icon) },
                          }),
                          _vm._v(_vm._s(item.name) + " "),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "cp-split-line" }),
              _c(
                "el-dropdown",
                { on: { command: _vm.userMenu } },
                [
                  _c("div", { staticClass: "nav-box" }, [
                    _c("i", { staticClass: "cp-icon" }, [_vm._v("")]),
                  ]),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "cp-home-dropdown",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown",
                    },
                    [
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "backindex" } },
                        [_vm._v(" 系统主页 ")]
                      ),
                      _vm.localDeployment && !_vm.isDaqsoftOs
                        ? _c(
                            "el-dropdown-item",
                            { attrs: { command: "quit", divided: "" } },
                            [_vm._v(" 退出系统 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("cp-weather-dialog", { attrs: { show: _vm.show, data: _vm.weather } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cp-header-line" }, [
      _c("div", { staticClass: "cp-header-left" }),
      _c("div", { staticClass: "cp-header-center" }),
      _c("div", { staticClass: "cp-header-right" }, [
        _c("div", { staticClass: "cp-header-right-info" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }