<template>
  <div class="cp-home-content">
    <div class="cp-home-header" v-if="showHeader">
      <!-- 蓝色主题 -->
      <cp-sby-header v-if="themeData === 'blue'" @on-command="scenicCommand"></cp-sby-header>
      <!-- 黄色主题 -->
      <cp-header v-else-if="themeData === 'yellow'" @on-command="scenicCommand"></cp-header>
    </div>
    <iframe v-if="reloadiframe && subsystemUrl"
            id="containerFrm2"
            v-loading.fullscreen.lock="loading"
            class="container"
            :class="{'policy-decision': isPolicy(iframeInfo.name)}"
            element-loading-text="正在加载，请稍后"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            :src="subsystemUrl"></iframe>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CpHeader from './components/header';
import CpSbyHeader from './components/sby-header';
import scenicManagementPlatformConfig from "scenicManagementPlatformConfig";
export default {
  name: 'Home',
  components: {
    CpHeader,
    CpSbyHeader
  },
  data() {
    return {
      projectName: scenicManagementPlatformConfig.projectName,
      themeData: '',
      pagetype: '',
      iframeInfo: '',
      reloadiframe: true,
      loading: true,
      // 层级菜单
      levelList: [],
      site: {
        // 集团站点
        groups: [],
        // 景区站点
        scenic: [],
        // 默认站点
        defaultCompany: {}
      },
      // 是否修改密码
      ischangepwd: false,
      // 是否是本地部署
      localDeployment: false,
      flag: false,
      // 加载成功后再加载头部
      showHeader: false
    };
  },
  computed: {
    ...mapGetters({
      token: 'token',
      user: 'user',
      siteId: 'siteId'
    }),
    userInfo() {
      return this.user && this.user.employee || {};
    },
    currentSite() {
      let site = this.site.scenic.find(item => item.id === this.siteId && item.accountId === this.user.account.id);
      if (!site) {
        site = this.site.groups.find(item => item.id === this.siteId && item.accountId === this.user.account.id);
        if (!site) {
          return null;
        }
      }
      return site;
    },
    subsystemUrl() {
      if (this.iframeInfo && this.iframeInfo.thirdUrl) {
        const isInfo = this.iframeInfo.thirdUrl.includes('?');
        return isInfo ? `${ this.iframeInfo.thirdUrl }&thrid=1` : this.iframeInfo.thirdUrl;
      } else {
        return '';
      }
    }
  },
  watch: {
    token(token) {
      // token变化必须重新获取
      if (token) {
        this.$store.dispatch('getUserInfo');
        this.getSiteList();
      }
    },
    siteId(id) {
      id && this.changeSite(id);
    }
  },
  created() {
    this.pagetype = this.$route.query.type;
    this.getTheme();
  },
  mounted() {
    this.flag = sessionStorage.getItem('iframe') || false;
  },
  beforeRouteEnter(to, from, next) {
    // 通过 `vm` 访问组件实例
    next((vm) => {
      // 获取地址栏的token
      const token = sessionStorage.getItem('urlToken');
      vm.initToken(token, 'enter');
    });
  },
  beforeRouteUpdate(to, from, next) {
    const token = sessionStorage.getItem('urlToken');
    this.initToken(token, 'update');
    // 路由变化时，设置
    next();
  },
  methods: {
    getTheme() {
      this.$api.getTheme().then((res) => {
        if (res.code === 0) {
          this.themeData = res.data.themeSkin || 'yellow';
        } else {
          this.$notice.operateError('获取失败', res.message || '');
        }
      })
        .catch((err) => {
          this.$notice.operateError('获取失败', err.message || '');
        });
    },
    isPolicy(name) {
      let list = ['决策分析', '综合数据分析', '游客数据分析'];
      return list.includes(name);
    },
    // 根据菜单设置相关页面功能
    scenicCommand(val) {
      this.loading = true;
      this.iframeInfo = val;
      console.log(val);
      setTimeout(() => {
        this.loading = false;
      }, 200);
    },

    /**
     * [初始化页面]
     * @param {[String]} token [传入的token]
     * @param {[String]} type [enter: 路由进入时，update: 路由更新时]
     * @returns
   */
    initToken(token, type) {
      // 只要刷新页面就必须重新获取（因为有可能获取失败，后期可以判断下）
      if (token && token !== this.token) {
        if (type === 'update') {
          this.$store.commit('token', this.token);
        } else {
          this.$store.commit('token', token);
        }
      } else if (this.token) {
        this.getSiteList();
      } else {
        this.$router.push({
          path: '/login'
        });
      }
    },
    getSiteList() {
      this.showHeader = true;
      // 获取站点列表
      this.$api.getSiteList().then((res) => {
        const data = res.data;
        // console.log(data);
        this.site.groups = data.groups || [];
        this.site.scenic = data.scenic || [];

        if (data.defaultCompany) {
          this.site.defaultCompany = data.defaultCompany;
        } else {
          // TODO 获取配置的默认站点，访问站点
          const group = this.site.groups[0];
          if (group) {
            this.site.defaultCompany = group;
          }
        }
      })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@include b(home-content) {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #020b17;
  @include b(home-header){
    width: 100%;
    height: 73px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
  }
  .container {
    height: 100%;
    width: 100%;
    border-width: 0;
    display: flex;
    background-color: #020b17;
    &.policy-decision{
      position: absolute;
      height: calc(100% - 78px);
      width: calc(100% - 30px);
      top: 78px;
      left: 15px;
    }
  }
}
</style>
