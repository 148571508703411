<template>
  <div class="cp-home-header">
    <div class="cp-header-line">
      <div class="cp-header-left"></div>
      <div class="cp-header-center"></div>
      <div class="cp-header-right">
        <div class="cp-header-right-info"></div>
      </div>
    </div>
    <div class="cp-header-info">
      <div class="cp-header-left-info">
        <div class="cp-visual-weather">
          <i
            v-if="todayWeather.cond && todayWeather.cond.unicode_d"
            style="margin-right: 5px;font-size: 16px;"
            class="daq-weather cp-visual-weather-icon"
            v-html="todayWeather.cond.unicode_d"
          ></i>
          <p class="cp-visual-weather__left-weather">
            {{ weatherInfo }}
          </p>
          <div class="cp-split-line"></div>
          <p class="cp-visual-infos__time">
            <span class="time-today" style="margin-left:5px">{{ today }}</span>
            <span class="time-today time-today-now">{{ nowTime }}</span>
          </p>
          <div class="cp-split-line"></div>
          <p class="cp-visual-weather__btn" @click="showWeatherDialog">
            <span>气象数据</span><i class="cp-icon">&#xea9c;</i>
          </p>
        </div>
      </div>
      <div class="cp-header-title">
        <template v-if="selectInfo.name">
          <img
            v-if="selectInfo.iconUrl"
            :src="selectInfo.iconUrl"
            :alt="selectInfo.name"
            height="50px"
          />
          <div v-else-if="systemLogo || systemName" class="cp-header-logo">
            <div v-if="systemLogo" class="logo-pic">
              <img
                :src="systemLogo"
                width="50px"
                height="50px"
                :alt="systemName"
              />
            </div>
            <div class="web-name">
              {{
                systemName
                  ? `${systemName}-${selectInfo.name}`
                  : selectInfo.name
              }}
            </div>
          </div>
        </template>
      </div>
      <div class="cp-header-right-info">
        <div class="navigation-list">
          <el-dropdown placement="bottom" trigger="click" @command="scenicMenu">
            <div class="nav-box">
              <i class="cp-icon">&#xe682;</i>
            </div>
            <el-dropdown-menu slot="dropdown" class="cp-home-dropdown">
              <el-dropdown-item
                v-for="(item, index) in scenicHomeMenu"
                :key="`menu-${index}`"
                :divided="index !== 0"
                :command="item.name"
                :class="{ selectMenu: item.name === selectInfo.name }"
              >
                <i class="cp-icon" v-html="item.icon"></i>{{ item.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="cp-split-line"></div>
          <el-dropdown @command="userMenu">
            <div class="nav-box">
              <i class="cp-icon">&#xe67e;</i>
            </div>
            <el-dropdown-menu slot="dropdown" class="cp-home-dropdown">
              <el-dropdown-item command="backindex">
                系统主页
              </el-dropdown-item>
              <el-dropdown-item
                v-if="localDeployment && !isDaqsoftOs"
                command="quit"
                divided
              >
                退出系统
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <cp-weather-dialog :show="show" :data="weather"> </cp-weather-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import comm from "@/utils/comm.js";
import scenicManagementPlatformConfig from "scenicManagementPlatformConfig";
export default {
  name: "CpHeader",
  props: {},
  data() {
    return {
      // 是否在大旗OS内打开
      isDaqsoftOs: comm.isDaqsoftOs(),
      // 是否是本地部署
      localDeployment: false,
      scenicHomeMenu: [],
      selectInfo: {},
      screenLogo: {},
      isConfigInfo: false,
      changeLeftItem: 0,
      list: [],
      today: "",
      nowTime: "",
      // 天气数据
      weather: {},
      // 是否显示天气弹窗
      show: false,
      weatherPrivateSecret: "",
      weatherPublicSecret: ""
    };
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
    todayWeather() {
      return this.weather.daily_forecast && this.weather.daily_forecast.length
        ? this.weather.daily_forecast[0]
        : {};
    },
    weatherInfo() {
      let info = `${(this.todayWeather.cond && this.todayWeather.cond.txt_d) ||
        ""}`;
      if (this.todayWeather.tmp) {
        info += ` ${this.todayWeather.tmp.min}°~${this.todayWeather.tmp.max}°`;
      }
      return info || "";
    },
    systemLogo: {
      get() {
        return this.screenLogo.logoUrl || "";
      }
    },
    systemName: {
      get() {
        // 更改页面默认favicon图标
        if (this.screenLogo.faviconUrl) {
          this.changeIcon(this.screenLogo.faviconUrl);
        }
        // 更改页面默认标题
        if (this.screenLogo.title) {
          window.document.title = this.screenLogo.title;
        }
        return this.screenLogo.title || "";
      }
    }
  },
  created() {
    const that = this;
    // 每秒获取当前时间
    setInterval(() => {
      that.getNowTime();
      if (that.nowTime === "0:00:00") {
        this.getToday();
      }
    }, 1000);
    this.getToday();

    // 获取配置信息
    this.getIntegratedScreenConfig();
    this.getWeather();
  },
  mounted() {
    if (window.scenicManagementPlatformConfig.isLocal) {
      // 巫山项目
      this.localDeployment = true;
    } else {
      this.localDeployment = false;
    }
  },
  methods: {
    // 获取当前日期
    getToday() {
      const myDate = new Date();
      const tYear = myDate.getFullYear();
      const tMonth = myDate.getMonth();
      const tDay = myDate.getDate();

      let m = tMonth + 1;
      if (m.toString().length === 1) {
        m = `0${m}`;
      }
      this.today = `${tYear}-${m}-${tDay < 10 ? `0${tDay}` : tDay}`;
    },
    // 获取系统时间
    getNowTime() {
      const myDate = new Date();
      const hour = myDate.getHours();
      const min = myDate.getMinutes();
      const sec = myDate.getSeconds();
      this.nowTime = `${hour}:${min < 10 ? `0${min}` : min}:${
        sec < 10 ? `0${sec}` : sec
      }`;
    },
    changeIcon(icourl) {
      const changeFavicon = link => {
        let $favicon = document.querySelector('link[rel="icon"]');
        if ($favicon !== null) {
          $favicon.href = link;
        } else {
          $favicon = document.createElement("link");
          $favicon.rel = "icon";
          $favicon.href = link;
          document.head.appendChild($favicon);
        }
      };
      // 动态修改网站图标
      changeFavicon(icourl);
    },
    getIntegratedScreenConfig() {
      // 先获取页面LOGO
      this.getIntegratedScreenlogo();
      const objs = {customBaseUrl: window.scenicManagementPlatformConfig.customBaseUrl || ""};
      // 查询开关和平铺状态
      this.$api
        .getIntegratedScreenMenuList(objs)
        .then(res => {
          if (res.code === 0) {
            this.scenicHomeMenu = res.datas || [];
            if (this.scenicHomeMenu.length > 0) {
              this.scenicMenu();
            }
          } else {
            this.$notice.operateError("获取状态失败", res.message || "");
          }
        })
        .catch(err => {
          this.$notice.operateError("获取状态失败", err.message || "");
        });
    },
    // 获取综合大屏LOGO信息
    getIntegratedScreenlogo() {
      this.$api
        .getIntegratedScreenlogo()
        .then(res => {
          if (res.code === 0) {
            this.screenLogo = res.data;
          } else {
            this.$notice.operateError("获取状态失败", res.message || "");
          }
          return;
        })
        .catch(err => {
          this.$notice.operateError("获取状态失败", err.message || "");
        });
    },
    // 总览导航功能
    scenicMenu(...command) {
      const homeMenu = sessionStorage.getItem("homeMenu") || "";
      let info = null;
      if (homeMenu && !command[0]) {
        info = JSON.parse(homeMenu);
      } else {
        info = command[0]
          ? this.scenicHomeMenu.find(v => v.name === command[0])
          : this.scenicHomeMenu[0];
      }
      if (command[0]) {
        const currentScenicHomeMenu = this.scenicHomeMenu.find(
          v => v.name === command[0]
        );
        sessionStorage.setItem(
          "homeMenu",
          JSON.stringify(currentScenicHomeMenu)
        );
      }
      if (info && info.thirdUrl) {
        this.selectInfo = info;
        this.$emit("on-command", info);
      } else {
        this.$notice.popupWarning("功能暂未上线");
      }
    },
    // 用户操作功能
    userMenu(command) {
      switch (command) {
        case "quit":
          this.$confirm("即将退出系统, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
            .then(() => {
              this.$api
                .loginOut()
                .then(() => {
                  this.$store.commit("clear");
                  this.$store.commit("token", "");
                  setTimeout(() => {
                    if (this.localDeployment) {
                      // 本地部署
                      top.location.href = `${window.location.protocol}//${window.location.host}/scenic/login`;
                    } else {
                      top.location.href =
                        sessionStorage.getItem("entrance") ||
                        window.scenicManagementPlatformConfig.entrance;
                    }
                  }, 200);
                })
                .catch(data => {
                  this.$notice.operateError("提示", `${data.message}`);
                  this.$store.commit("clear");
                  setTimeout(() => {
                    if (this.localDeployment) {
                      // 本地部署
                      top.location.href = `${window.location.protocol}//${window.location.host}/scenic/login`;
                    } else {
                      top.location.href =
                        sessionStorage.getItem("entrance") ||
                        window.scenicManagementPlatformConfig.entrance;
                    }
                  }, 200);
                });
            })
            .catch(() => {
              // 取消
            });
          break;
        case "changePassword":
          this.ischangepwd = true;
          break;
        case "backindex":
          this.$router.push("/index");
          break;
        case "systemSettings":
          this.changeSkin();
          break;
        default:
          break;
      }
    },
    changItem(index) {
      this.changeLeftItem = index;
    },
    // 关闭系统配置窗口
    closeDialog() {
      this.isConfigInfo = false;
    },
    // =====================================获取天气参数====================================//
    getweatherConfig() {
      this.$api.getweatherConfig().then(res => {
        this.weatherPrivateSecret = res.data.weatherPrivateSecret;
        this.weatherPublicSecret = res.data.weatherPublicSecret;
        this.getWeather();
      });
    },
    // 获取天气
    getWeather() {
      let baseURL = scenicManagementPlatformConfig.weather;
      const abUrl = new RegExp('^https|http');
      if (!abUrl.test(baseURL)) {
        baseURL = location.protocol + baseURL;
      }
      axios
        .get(
          `${baseURL}?clientId=4a1641090d&code=${
            this.user.umsCompany.region
          }&sign=584488EEBA66E740AA57B638B6B6A43939CE3852`
        )
        .then(res => {
          if (res.data.code === 0) {
            this.weather = res.data.data;
          }
        });
    },
    // 显示天气弹窗
    showWeatherDialog() {
      this.show = true;
    },
    // 退出
    handleCommand() {
      this.$api.loginOut(2).then(res => {
        if (res.code === 0) {
          sessionStorage.clear();
          localStorage.clear();
          this.$store.commit("resetStore");
          setTimeout(() => {
            top.location.href = `${window.location.protocol}//${window.location.host}/scenic/login`;
          }, 200);
        } else {
          this.$notice.popupWarning(`${res.message}退出失败，请稍后再试！`);
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.time-today {
  margin-left: 10px;
}
@include b(home-header) {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 73px;
  color: #fff;
  z-index: 2;
  font-family: "宋体", "Times New Roman";
  @include b(header-info) {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 93px;
    background-image: url(../../../assets/images/top.png);
    background-repeat: no-repeat;
    background-position: top center;
    z-index: 4;
    @include b(split-line) {
      height: 14px;
      width: 1px;
      background-color: rgba($color: $fPrimaryHl, $alpha: 0.4);
      margin: 0 15px;
    }
    @include b(header-left-info) {
      width: calc((100% - 718px) / 2);
      height: 80px;
      @include b(visual-weather) {
        display: flex;
        height: 60px;
        align-items: center;
        p {
          color: $fPrimaryHl;
        }
        @include e(img) {
          width: 23px;
          height: 23px;
          margin-right: 5px;
        }
        @include e(left-weather) {
          line-height: 26px;
          font-size: $f16;
          font-weight: 700;
        }
        @include e(btn) {
          display: flex;
          align-items: center;
          width: 88px;
          height: 24px;
          color: $fPrimaryHl;
          font-size: $f16;
          cursor: pointer;
          i {
            color: $fPrimaryHl;
            font-size: $f14;
          }
        }
      }
    }
    @include b(header-title) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 680px;
      height: 72px;
      @include b(header-logo) {
        display: flex;
        align-items: center;
        height: 50px;
        > .web-name {
          font-size: 32px;
          font-weight: bold;
          background-image: linear-gradient(to top, #fce6a7, #ffffff);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-left: 10px;
        }
      }
    }
    @include b(header-right-info) {
      width: calc((100% - 718px) / 2);
      height: 80px;
      display: flex;
      justify-content: flex-end;
      color: $fPrimaryHl;
      > .navigation-list {
        display: flex;
        height: 60px;
        align-items: center;
        .nav-box {
          height: 15px;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          cursor: pointer;
        }
        .cp-icon {
          font-weight: bold;
          color: $fPrimaryHl;
        }
        .cp-icon:hover {
          color: $fPrimaryHlHover;
        }
      }
    }
  }
}
@include b(header-line) {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  @include b(header-left) {
    width: calc((100% - 718px) / 2);
    height: 80px;
    background-image: url(../../../assets/images/di_01.png);
    background-repeat: no-repeat;
    background-size: 100% 80px;
  }
  @include b(header-center) {
    width: 718px;
    height: 80px;
    background-image: url(../../../assets/images/di_02.png);
    background-repeat: no-repeat;
    background-size: 100% 93px;
  }
  @include b(header-right) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: calc((100% - 718px) / 2);
    height: 80px;
    background-image: url(../../../assets/images/di_03.png);
    background-repeat: no-repeat;
    background-size: 100% 80px;
  }
}
</style>
